.columnTitle {
    display: flex;
    align-items: center;
}

.delBtn, .editBtn {
    padding: 5px;
}

.addBtn{
    margin-left: 15px;
}

.content {
   display: flex;
   height: 100%;
}

.scrollContent{
    display: flex;
    height: 90vh;
    overflow-x: scroll; 
}

.oneColumn{
    height: 100% !important;
}

.oneColumnWrapper{ 
    height: 90vh;
}