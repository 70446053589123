.body {
    background: #001529;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formContainer {
    height: 400px;
    width: 400px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    flex-direction: column;
}

.logo {
    height: 45px;
}

.logoContainer {
    height: 60px;
    width: 200px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 50px 0;
    background: #001529;
}

.errorContainer {
    margin: 0;
}

.error {
    color: red;
    width: 100%;
    margin-left: 50px;
}

.logoText {
    color: white !important;
    padding: 0 20px;
}