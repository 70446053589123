.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.ant-table-content {
  overflow-x: auto!important;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.ant-checkbox-wrapper {
  padding-left: 5px;
  margin: 0 !important;
}

.ant-table-cell {
  padding: 12px!important;
}

.ant-collapse-header {
  padding: 0!important;
}

.ant-collapse-content-box {
  padding: 0!important;
}

.ant-collapse-arrow {
  height: 10px !important;
  width: 10px !important;
}

.ant-collapse-expand-icon {
  padding-right: 5px!important;
}

.ant-card-body {
  max-width: inherit;
}

.ant-message-notice-content span{
  width: 100%;
}

.ant-message-notice-content {
  display: flex !important;
  justify-content: center;
  max-width: 250px!important;
  margin: auto!important;
}

.ant-message-custom-content {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ant-message-notice-content .anticon{
  width: auto !important;
}

.ant-tooltip {
  max-width: 400px;
}

.dragHandler:hover {
  cursor: move;
}
.ant-table-footer{
  padding: 0px!important;
  color: rgba(0, 0, 0, 0.88);
  border-top: 0!important;
  position: sticky!important;
  bottom: 0!important;
}
/*.ant-table-content:hover{*/
/*  overflow-x: scroll!important;*/
/*}*/

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}
