.input {
    max-width: 350px!important;
}

.title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}


.checkboxes {
    margin: 0 !important;
}

.schedule {
    margin: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.timezone {
    display: flex;
}

.scheduleContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.scheduleDays {
    display: flex;
    flex-direction: column;
}

.addDayButton {
    margin-top: 15px;
    width: 115px;
}

.dayPicker {
}

.timePicker {
    width: 250px;
    margin-left: 25px;
    margin-right: 25px;
}

.scheduleItem {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.deleteIcon {
    font-size: 20px;
    cursor: pointer;
    color: red;
}

.scheduleErrorMsg {
    color: red;
    margin-top: 15px;
}

.ordersTableButtonContainer {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 25px;
}

.addButton {
    margin-bottom: 15px;
}
