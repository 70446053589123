.formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.formItem {
    max-width: 250px;
    margin-left: 15px;
}

.formItem250 {
    max-width: 250px !important;
    width: 250px !important;
    margin-left: 15px;
}

.formItemWithSwicher {
    align-items: baseline !important;
    display: flex !important;
    gap: 40px !important;
}

.formItemWithSwicherWraper{
    margin-bottom: -5px;
}

.depositText{
    margin-left: 15px;
}

