.tooltipList{
    max-width: 80vh;
    overflow-y: auto;
    max-height: 60vh;
} 

.tooltipListItem {
    color: white !important;
    padding: 0px !important;
    margin: 0px;
}

.tooltipButton {
    border: none;
    margin: 7px 0;
    box-shadow: 0;
    height: 25px;
    background: #1677ff;
    box-shadow: none !important;
}

.buttonText {
    vertical-align: super;
    font-size: 12px;
    color: #fff;
    margin-top: -2px;
}

.tooltipButtonActive, .tooltipButton:focus, .tooltipButton:hover {
  color: #1677ff !important;
  box-shadow: none !important;
}
