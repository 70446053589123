.iconClass{
    color: var(--cornflower-blue-collor);
    @apply text-2xl
}

.buttonDark{
    @apply bg-transparent border-0 rounded-full p-1 hover:bg-gray-700 active:bg-gray-600 focus:bg-gray-700
}

.buttonLight{
    @apply bg-transparent border-0 rounded-full p-1 hover:bg-gray-200 active:bg-gray-300 focus:bg-gray-200
}
