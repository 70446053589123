.tooltipList{
    max-width: 80vh;
    overflow-y: auto;
    max-height: 60vh;
    scrollbar-width: thin;
} 

.tooltipList::-webkit-scrollbar {
    width: 5px;
  }
   
.tooltipList::-webkit-scrollbar-track {
  background: #ddd;
}
   
.tooltipList::-webkit-scrollbar-thumb {
  background: #1677ff; 
}

.tooltipListItem {
    color: white !important;
    padding: 4px 0 !important;
    margin: 0 8px;
}

.tooltipButton, .tooltipButtonActive {
    padding: 5px;
    border-radius: 35%;
    cursor: pointer;
}

.tooltipButtonActive, .tooltipButton:focus {
  color: #1677ff !important;
}


.tooltipButton, .tooltipButton:focus, .tooltipButton:active {
    border: none;
}