.tableHeader {
    display: flex;
    justify-content: space-between;
}

.filter {
    margin-bottom: 10px !important;
}

.modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.legendWraper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.legendEl {
    margin-right: 5px;
    text-align: center;
    line-height: 0.9em;
}

.legendEl :first-child{
    margin-bottom: 5px;
}

.legendDot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

.filters {
    flex-grow: 1;
}

.addButton {
    margin-left: 15px;
}

.switchWraper{
    margin-top: 20px!important;
}