.columnTitle {
    display: flex;
    align-items: center;
}

.delBtn, .editBtn {
    padding: 5px;
}

.oneColumn{
    height: 100% !important;
}