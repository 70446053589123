.editor {
    /*display: flex;*/
    /*justify-content: center;*/
    /*width: 100% !important;*/
}

.input {
    max-width: 350px!important;
}

.title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.addButton {
    margin-bottom: 15px;
}

.editorLabel {
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

