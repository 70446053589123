.modalWrap{
    position: fixed;
    inset: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(22, 119, 255, 0.72);
    gap: 10px;
}

.modalMask{
    position: fixed;
    inset: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    pointer-events: none;
}
