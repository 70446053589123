.minusBtn {
    color: red;
    padding: 1px 6px;
}

.listItem {
    padding: 4px 6px !important;
}

.plusBtn {
    color: rgb(41, 133, 41);
    padding: 6px 7px;
}

.title {
    display: flex;
    justify-content: end;
    padding:  0 5px;
}

.plusDiv{
    display: flex;
    justify-content: space-between;
}