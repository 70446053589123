.input {
    max-width: 350px;
}

.checkbox{
    padding-left: 35px;
}

.title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}


.checkboxes {
    margin: 0 !important;
}