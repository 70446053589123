.icon {
    margin: 16px 0 16px 16px;
    overflow: hidden;
    height: 35px;
}

.layout {
    height: 100vh;
    overflow: auto;
}

.layoutContent {
    height: 100%;
}


.header {
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 0px;
    display: flex;
    justify-content: space-between;
}

.profile {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-right: 25px;
}

.content {
    padding: 24px;
    margin-top: 15px;
    min-height: 360px;
    border-radius: 10px;
}

.contentContainer {
    height: 100%;
    /*margin: 0 16px;*/
}

.iconContainer {
    display: flex;
}

.collapseButton {
    flex-grow: 1;
    margin-left: 5px;
    color: white;
    height: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 5px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileItemText {
    margin-left: 10px;
    margin-bottom: 5px;
}

.profileItem {
    display: flex;
    align-items: center;
}

.title {
    font-size: 20px;
}

.logoText {
    color: white !important;
    padding: 0 20px;
}

.AppLayout_content__l-kK0{
    height: 100%;
}