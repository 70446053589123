.columnTitle, .categoryTitleBox {
    display: flex;
    align-items: center;
}

.delBtn, .editBtn {
    padding: 5px;
}

.categoryTitle {
    text-align: center;
}

.btnsGroup {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.categoryTitleBox {
    justify-content: space-between;
}

.backArrow{
    margin-bottom: 10px;
}

.scrollContent{
    display: flex;
    height: 90vh;
    overflow-x: scroll; 
}

.oneColumn{
    height: 100% !important;
}

.oneColumnWrapper{ 
    height: 90vh;
}